
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({})
export default class GiveawayChat extends Vue {
    logged_in = false;
    users_count = 1;
    messages: {name: string, message: string, timestamp: number}[] = [];
    @Prop({default: null}) name!: string|null;
    new_message = "";
    @Prop() model_id!: string;
    sendMessage (e: Event) {
        if (e) e.preventDefault()
        if (!this.new_message||this.new_message.length<1) return;
        this.$socket.client.emit('message', {
            model_id: this.model_id,
            authorization: `Bearer ${this.$store.getters.CUSTOMER_TOKEN}`,
            message: this.new_message,
            name: this.name
        })
        this.new_message = '';
    }

    recivedMessage(message: any) {
        if (message.timestamp<this.messages[0]?.timestamp) return;
        this.messages = [message, ...this.messages.slice(0, 25)];
    }

    updateOnlineWatchers (count: number) {
        this.users_count = count
    }

    @Watch('$socket.connected')
    setupSubscribes () {
        this.$socket.client.off('new-message');
        this.$socket.client.off('online-watchers');
        this.$socket.client.emit('watch', {model_id: this.model_id, authorization: `Bearer ${this.$store.getters.CUSTOMER_TOKEN}`}, (count: number) => {
            this.users_count = count;
        })

        this.$socket.client.on('new-message', this.recivedMessage)

        this.$socket.client.on('online-watchers', this.updateOnlineWatchers)
    }

    mounted () {
        this.logged_in = this.$store.getters.CUSTOMER_TOKEN!==null;
        this.name = this.$store.getters.name;
        this.setupSubscribes();
    }
}
