
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({})
export default class GetName extends Vue {
    logged_in = false;
    name: string|null = null;
    new_name = "";
    @Prop() model_id!: string;
    setName (e?: Event) {
        e?.preventDefault()
        this.name=this.new_name;
        this.$store.commit('setName', this.new_name);
        this.new_name = '';
        this.$emit('nameChanged', this.name);
    }
    mounted () {
        this.logged_in = this.$store.getters.CUSTOMER_TOKEN!==null;
        this.name = this.$store.getters.name;
    }
}
