
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({})
export default class Countdown extends Vue {
  @Prop({default: false}) counting!: boolean;
  count = 3;
  interval: number|null = null;
  countdown () {
      this.count = this.count - 1;
      if (this.count<=1 && this.interval!==null) {
          this.count = 1;
          clearInterval(this.interval)
          this.interval = null;
          this.$nextTick(() => {
            this.$emit('done');
          })
      }
  }

  @Watch('counting')
  startCounting () {
    if (!this.counting) return;
    this.count = 3;
    this.interval = setInterval(this.countdown, 2000);
    this.$emit('started');
  }
}
