
import { Component, Prop, Vue } from "vue-property-decorator";
import ImageOverCard from '@/components/ImageOverCard.vue'
import { Winner } from "@/plugins/api";
@Component({
  components: {
      ImageOverCard
  }
})
export default class GiveawayWinnerList extends Vue {
    @Prop() winners!: Winner[];
    @Prop() prize!: string[];
    winners_to_show: Winner[] = [];
    private readonly places = ['اول', 'دوم', 'سوم', 'چهارم', 'پنجم', 'ششم', 'هفتم', 'هشتم', 'نهم', 'دهم'];
    private insta_img = require('@/assets/images/instagram.png');
    private img = require("@/modules/Profile/assets/done_giveaways.png");

    getWinners () {
        const temp = this.winners;
        this.winners_to_show = temp;
    }

    mounted () {
        this.getWinners();
    }
}
