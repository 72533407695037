
import { Component, Vue, Watch } from 'vue-property-decorator';
import Countdown from '../components/RollingCountdown.vue';
import Particles from '../components/RollingParticles.vue';
import GiveawayRoller from '../components/GiveawayRoller.vue';
import GiveawayChat from '../components/GiveawayChat.vue';
import GiveWinnerList from '../components/GiveawayWinnerList.vue';
import GetName from '../components/GetName.vue';
import { GetGiveawayResponse } from '@/plugins/api';

enum GiveawayStatus {
    payment_required = 0,
    active = 1,
    stoped = 2,
    before_rolling = 3,
    done = 4,
    prize_paid = 5,
    spam = 6,
    archived = 7
}

@Component({
  metaInfo: {
    title: 'برگزاری قرعه‌کشی',
    meta: [
      {name: 'description', content: 'شرکت و مشاهده قرعه‌کشی لاکی لایو. بهترین ربات قرعه‌کشی و محیط برگزاری برای همه افراد.'}
    ]
  },
  components: {
    Countdown,
    Particles,
    GiveawayRoller,
    GiveawayChat,
    GiveWinnerList,
    GetName
  },
})
export default class RollingGiveawayPage extends Vue {
  done = false;
  state: 'payment_required'|'remaining'|'stoped'|'idle'|'playing'|'done'|'archive'= 'remaining';
  total_chances = 3;
  chance = 1;
  giveaway: GetGiveawayResponse|null= null;
  noInteraction = false;
  loading = false;
  remaining: string|null= 'در حال محاسبه';
  name = null;
  private readonly audio_file = require('../assets/cheering.mp3');
  audio: HTMLAudioElement|null = null;
  
  public get showChat() : boolean {
    return this.giveaway!==null&&this.giveaway.status>2&&this.giveaway.status<5
  }
  public get showWinnerList() : boolean {
    return this.giveaway!==null&&(this.done||this.giveaway.status>4)
  }
  
  private nextState = {
    idle: 'playing',
    playing: 'idle',
  }

  handleRollerClick (e?: Event): void{
    e?.preventDefault()
    if (!this.giveaway) return;
    if (this.noInteraction) return;
    if (this.giveaway?.status<=3) {
      this.$socket.client.emit('start', {
        model_id: this.giveaway?.model_id,
        authorization: `Bearer ${this.$store.getters.CUSTOMER_TOKEN}`,
        is_owner: this.giveaway.viewer?.is_owner
      })
      return;
    }
    this.state = (this.nextState as any)[this.state];
  }

  handleRollingStarted () {
    this.noInteraction = true;
  }

  handleWinnerSelected (): void{
    this.chance += 1;
    if (this.chance>(this.giveaway?.winners.length||1)) {
      this.done = true;
      this.state = 'done';
      this.chance = this.total_chances;
    } else {
      setTimeout(() => {
        this.handleRollerClick()
        setTimeout(() => {
        this.handleRollerClick()
      }, 4000);
      }, 2000);
    }
    this.noInteraction = false;
    this.audio?.play()
  }

  calculateRemaining (): string|null {
    if (this.giveaway && this.giveaway.status>2) return this.remaining =  null;
    if (!this.giveaway?.remaining) return this.remaining = '~';
    const mins = this.giveaway.remaining / 60;
    const hours = mins / 60;
    const days = hours / 24;
    const weeks = days / 7;
    if (mins<0) return '~'
    if (mins<60) return this.remaining = Math.round(mins) + ' دقیقه';
    if (hours<24) return this.remaining = Math.round(hours) + ' ساعت';
    if (days<60) return this.remaining = Math.round(days) + ' روز';
    else return this.remaining = Math.round(weeks) + ' هفته';

  }

  async getGiveaway () {
    this.loading = true;
    const id = this.$route.params.give_id;
    const states: ('payment_required'|'remaining'|'idle'|'stoped'|'playing'|'done'|'archive')[]
      = ['payment_required', 'remaining', 'stoped', 'idle', 'idle', 'archive', 'archive', 'archive'];
    try {
      const res = await this.$api.getGiveaway(id);
      this.giveaway = res.data;
      this.total_chances = this.giveaway.winners.length||this.giveaway.chances;
      this.state = states[this.giveaway?.status];
      this.calculateRemaining();
      await this.setupSocket();
    } catch (err) {
      console.log(err);
      this.$toast.error('لطفا دوباره تلاش کنید.');
    }
    this.loading = false;
  }


  giveSoClose (payload: any) {
    if (!this.giveaway) return this.getGiveaway();
    if (this.giveaway.model_id!==payload.model_id) return;
    if (!this.giveaway) return this.getGiveaway();
    this.giveaway.roller_users = payload.crawl.valid_enrolls;
  }
  giveIsReady (payload: any) {
    if (!this.giveaway) return this.getGiveaway();
    if (this.giveaway.model_id!==payload.model_id) return;
    this.giveaway.winners = payload.winners;
  }

  giveIsRolling (payload: any): void{
    if (!this.giveaway||this.giveaway?.model_id!==payload.model_id) return;
    this.giveaway.status = 4;
    this.state = (this.nextState as any)[this.state];
  }

  @Watch('$socket.connected')
  async setupSocket (): Promise<void>{
    if (this.$socket.connected)  {
      if (!this.loading) await this.getGiveaway();
      if (this.$socket.client.hasListeners('give-rolling')) return;
      this.$socket.client.on('give-ready', this.giveIsReady);
      this.$socket.client.on('give-rolling', this.giveIsRolling)
    } else {
      this.$socket.client.off('give-ready', this.giveIsReady);
      this.$socket.client.off('give-rolling', this.giveIsRolling)
    }
  }

  beforeDestroy () {
    this.$socket.client.off('give-ready', this.giveIsReady);
    this.$socket.client.off('give-rolling', this.giveIsRolling)
  }

  loadSound (): void {
    this.audio = new Audio(this.audio_file);
  }

  mounted () {
    if (!this.loading) this.getGiveaway();
    this.$nextTick(this.loadSound);
    this.name = this.$store.getters.name;
  }
}
