
import { Winner } from '@/plugins/api';
import { UserInstaAccount } from '@/types/gateway';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import RobberButton from './RobberButton.vue'
@Component({
    components: {
        RobberButton
    }
})
export default class Countdown extends Vue {
    @Prop({default: 'remaining'}) state!: string;
    @Prop({default: 1}) chance!: number;
    @Prop() remaining!: string;
    @Prop() winners!: Winner[];
    @Prop() enrols!: UserInstaAccount[];
    @Prop({default: false}) isOwner!: string;
    @Prop() model_id!: string;
    users: UserInstaAccount[] = [];
    winner_position: number[] = [];
    rollerStyle = {};
    remaining_modal = true;
    private basePosition = 0;
    private startPosition = 0;
    stack = 0;
    take_30 (): UserInstaAccount[]{
        const list = [];
        while (list.length<30) {
            if (this.stack>this.enrols.length-1) this.stack = 0;
            list.push(this.enrols[this.stack]);
            this.stack++;
        }
        return list;
    }

    handleCloseRemaining (): void{
        this.$router.replace({name: 'giveaway'})
    }

    setupRoller (): void{
        if (this.winners) {
            this.users = this.take_30()
                .map(u => {u.profile_pic_url=encodeURI(u.profile_pic_url); return u;});
            for (let i = 0; i < this.winners.length; i++) {
                const winner: UserInstaAccount = {username: this.winners[i].instagram_username, profile_pic_url: encodeURI(this.winners[i].avatar) };
                this.users = [...this.users, winner];
                this.winner_position.push(this.users.length-1);
                this.users = [...this.users, ...this.take_30().map(u => {u.profile_pic_url=encodeURI(u.profile_pic_url); return u;})];
            }
            this.users = [...this.users, ...this.take_30().map(u => {u.profile_pic_url=encodeURI(u.profile_pic_url); return u;})];
        }
        this.basePosition = -this.users.length*0.5*200
        this.startPosition = this.basePosition  + 200*3;
        this.rollerStyle = {
            transform: `translateX(${this.startPosition}px)`
        };        
    }

    @Watch('state')
    handleStateChange (): void{
        if (this.state!=='playing') return;
        const position = this.basePosition + 200 * this.winner_position[this.chance-1] +100
        this.rollerStyle = {
            transform: `translateX(${position}px)`
        }
        // this.chance += 1;
    }

    @Watch('winners')
    winnersChanged (): void{
        // if (this.winners?.length>0) this.setupRoller();
    }

    handleClick (e: Event): void{
        this.$emit('click', e)
    }
    dismissClick (e: Event): void{
        e.preventDefault();
        return;
    }

    mounted () {
        if (this.winners.length>0) {
            this.setupRoller()
        }
        if (this.remaining) {
            this.remaining_modal = this.remaining!==null;
            setTimeout(() => {
                this.$router.replace({name: 'giveaway'});
            }, 5000);
        }
    }
}
