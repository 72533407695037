
import { Component, Vue } from 'vue-property-decorator';
// import { ParticlesBg } from "particles-bg-vue";
// import Particles from "particles.vue";
@Component({
    components: {
// ParticlesBg
    }
})
export default class RollingParticles extends Vue {
    
private config = {
  "background": {
    "color": {
      // "value": "#8E72FF"
    }
  },
  "fullScreen": {
    "zIndex": -1
  },
  "particles": {
    "bounce": {
      "horizontal": {
        "value": 0
      },
      "vertical": {
        "value": 0
      }
    },
    "color": {
      "value": [
        "#FFA68E",
        "#90E0FB",
        "#FFDB79"
      ]
    },
    "move": {
      "decay": 0.1,
      "direction": "top",
      "enable": true,
      "gravity": {
        "enable": false,
        "maxSpeed": 1
      },
      "outModes": {
        "default": "destroy",
        "bottom": "bounce",
        "left": "destroy",
        "right": "destroy",
        "top": "none"
      },
      "speed": {
        "min": 50,
        "max": 150
      }
    },
    "number": {
      "limit": 300,
      "value": 0
    },
    "opacity": {
      "animation": {
        "speed": 0.3,
        "sync": true,
        "destroy": "min",
        "startValue": "max"
      }
    },
    "roll": {
      "darken": {
        "enable": true,
        "value": 30
      },
      "enable": true,
      "enlighten": {
        "enable": true,
        "value": 30
      },
      "speed": {
        "min": 15,
        "max": 25
      }
    },
    "rotate": {
      "value": {
        "min": 0,
        "max": 360
      },
      "animation": {
        "enable": true,
        "speed": 60
      },
      "direction": "random"
    },
    "shape": {
      "options": {
          "image": [] as {src: string, width: number, height: number}[],
          "images": [] as {src: string, width: number, height: number}[],
        "polygon": [
          {
            "sides": 5
          },
          {
            "sides": 6
          }
        ],
        "character": [
          {
            "value": [
              "💩",
              "🤡",
              "🍀",
              "🍙"
            ]
          }
        ]
      },
      "type": [
        "circle",
        "star",
        // "character",
        // "image",
        "image",
        "image",
      ]
    },
    "size": {
        random: {
            enable: true,
            minimumValue: 2,
        },
        value: {
            min: 2,
            max: 10
        },
      "animation": {}
    },
    "tilt": {
      "value": {
        "min": 0,
        "max": 360
      },
      "animation": {
        "enable": true,
        "speed": 60
      },
      "direction": "random",
      "enable": true
    },
    "wobble": {
      "distance": 30,
      "enable": true,
      "speed": {
        "min": -15,
        "max": 15
      }
    },
  },
  "emitters": {
    "autoPlay": true,
    "fill": true,
    "life": {
    count: 1,
    duration: 1,
    },
    "rate": {
      "quantity": 15,
      "delay": 0
    },
    "shape": "square",
    "startCount": 0,
    "size": {
      "mode": "percent",
      "height": 0,
      "width": 10
    },
    "position": {
      "x": 50,
      "y": 100
    }
  }
}

    created () {
        const images : {src: string, width: number, height: number}[] = [];
        for (let i = 1; i < 18; i++) {
            images.push({
                  src: require(`../assets/particles/${i}.png`),
                  width: 100,
                  height: 100
              })
            
        }
        this.config.particles.shape.options.image = images;
        this.config.particles.shape.options.images = images;
    }
}
