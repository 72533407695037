
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({})
export default class RobberButton extends Vue {
    @Prop({default: 'remaining'}) state!: string;
    @Prop({default: '~'}) remaining!: string;
    @Prop({default: false}) isOwner!: string;
    private play_requests: {class: string, at: number}[] = [];
    private play_request_stack = 1;
    handleClick (e: Event): void{
        this.$emit('click', e);
    }

    createNewPlayRequest () {
        if (this.play_request_stack==4) this.play_request_stack = 1;
        this.play_requests.push({class: 'request request-'+this.play_request_stack++, at: new Date().getTime()*0.001 + 20})
    }

    mounted () {
        setInterval(() => {
            const now = new Date().getTime()*0.001;
            this.play_requests = this.play_requests.filter(p => now<p.at)
        }, 10000)
        this.$socket.$subscribe('requested-start', this.createNewPlayRequest)
    }
}
