
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({})
export default class GiveawayDetailCard extends Vue {
  @Prop({default: true}) active!: boolean;
  @Prop() title!: string;
  @Prop() subtitle!: string;
  @Prop() img!: string;
  @Prop({default: true}) body!: boolean;
}
